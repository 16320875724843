<template>
  <div id="app">
      <div class="orientation-overlay d-flex flex-column justify-content-center align-items-center" v-if="isInPortrait == false">
        <div class="h4 d-flex flex-column justify-content-center align-items-center">
          <p class="h2"><b-icon icon="arrow-counterclockwise"></b-icon></p>
          <span class="ma-2 text-button">{{$t("applicationNotAvailableInLandscape")}}</span>
        </div>
      </div>
      <router-view :key="$route.fullPath"></router-view>
  </div>
</template>

<script>
    export default {
      name: 'app',
      created(){
        document.dispatchEvent(new Event('x-app-rendered')),
        window.addEventListener("orientationchange", e => {
          this.isInPortrait = process.env.NODE_ENV != 'production' ? null : screen.orientation.type.toLowerCase().includes("portrait")
        })
      },
      data(){
        return {
          isInPortrait: process.env.NODE_ENV != 'production' ? null : screen.orientation.type.toLowerCase().includes("portrait")
        }
      }
    }
</script>
<style scoped>
  #app{
    position: relative;
  }
  .orientation-overlay{
    position: absolute;
    z-index: 100000;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: rgb(var(--hot-food-color));
    color: white;
    text-align: center;
  }
</style>
