import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import './registerServiceWorker'
import MQTT from '@/assets/js/Food/MqttService'
import moment from "moment/min/moment-with-locales";

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import {langsGlobal} from "./mixins/langsGlobal"
import {pageInfoMixin} from "./mixins/pageInfoMixin"
import {nutriscoreMixin} from "./mixins/nutriscoreMixin"
import {customRouteMixin} from "./mixins/customRouteMixin"

import i18n from '@/i18n'
import router from '@/router'
import VueQrcodeReader from 'vue-qrcode-reader'
import Vue2TouchEvents from 'vue2-touch-events'
import '@mdi/font/css/materialdesignicons.css'
import VueMask from 'v-mask'
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

Vue.component('vue-phone-number-input', VuePhoneNumberInput);

moment.locale(localStorage.getItem("IEC_lang"))

Vue.use(Vue2TouchEvents, {
    touchClass: '',
    tapTolerance: 10,
    swipeTolerance: 30,
    longTapTimeInterval: 3000
})

require('@/assets/css/custom.css');
require('@/assets/css/bag.css');

Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueQrcodeReader)
Vue.use(VueMask);
Vue.mixin(langsGlobal)
Vue.mixin(pageInfoMixin)
Vue.mixin(nutriscoreMixin)
Vue.mixin(customRouteMixin)

Vue.prototype.$mqtt = MQTT;
Vue.prototype.$moment = moment;
router.beforeEach((to, from, next) => {
    let currentLoadedDemo = sessionStorage.getItem('IEC_loadedDemo') || null;
    let demoId = to.params.demoId || to.query.demoId || to.query.d || null;
    let demoToLoad = demoId || currentLoadedDemo
    if (demoToLoad) {
        sessionStorage.setItem('IEC_loadedDemo', demoToLoad)
        switch(demoToLoad){
            case 'leader-price':
                document.documentElement.style.setProperty('--hot-food-color', "50, 108, 173");
                break;
            default:
                break;
        }
        next()
    }else{
        if(from.params.demoId || from.query.demoId || from.query.d){
            to.params.demoId = from.params.demoId || from.query.demoId || from.query.d;
        }
        next()
    }
    next();
})
new Vue({
    i18n,
    router,
    el: '#app',
    render: h => h(App),
    created: () => {
        if(process.env.NODE_ENV == 'production' && document.location.host != "sap.wl.tc"){
            window.location.replace("https://sap.wl.tc/");
        }
    }
});
