export const langsGlobal = {
    data: function () {
        return {
            langs: {"en": "English", "de": "Deutsch", "fr": "Français", "es": "Español"}
        }
    },
    methods:{
        translate(word){
            let currentLang = this.$root.$i18n.locale
            let translated = word[currentLang]
            if(translated){
                return translated
            }
            for(let lang in this.langs){
                if(word[lang]){
                    translated = word[lang]
                    break;
                }
            }

            return translated
        }
    }
}