export const customRouteMixin = {
    methods: {
      formattedRoute(route) {
          let currentLoadedDemo = sessionStorage.getItem('IEC_loadedDemo');
  
          if(currentLoadedDemo != null){
              if(typeof(route) == 'object'){
                  route.params = Object.assign({}, route.params, {demoId: currentLoadedDemo.id});
  
              }else if(typeof(route) == 'string'){
                  return `/${currentLoadedDemo.id}${route}`
              }
          }
          return route;
      }
    }
  };