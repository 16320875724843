const URLS = {
    URL_WS: "https://weshop-ws-v2-weshop.pubqlf.co.as8677.net" ,
    URL_FACE_RECOGNITION: "https://facenet.rd.atosworldline.com",
    PWA_SHORT_URL: "https://awl.li/scanpayticketmcdo",
    URL_WS_CUSTOMIZATION:"https://wldd-yumi-demonstrations-wldd-tools.pubqlf.co.as8677.net",
    BASKET: {
        ADD_PRODUCT: () => { return URLS.URL_WS + "/basket/add/product"}, 
        CLEAR_BASKET:  () => {return URLS.URL_WS + "/basket/clear"},
        GET_BASKET_BY_STATE: () => {return URLS.URL_WS + "/basket/get/state?token={token}&state={state}"},
        GET_BASKET_BY_COMPLEX_ID: () => {return URLS.URL_WS + "/basket/get/{complexId}"}, 
        MANAGE_PRODUCTS: () => {return URLS.URL_WS + "/basket/manage/products"},
        VALID_BASKET: () => {return URLS.URL_WS + "/basket/valid"}, 
        SHARE_BASKET: () => {return URLS.URL_WS + "/basket/share"}, 
        VALID_SHARED_BASKET: () => {return URLS.URL_WS + "/basket/valid/shared"}, 
        INIT_LANE_PAYMENT: () => {return URLS.URL_WS + "/payment/terminal/init"}, 
        CHECK_LANE_PAYMENT: () => {return URLS.URL_WS + "/payment/terminal/check"}, 
        SET_SUPECT_BASKET: () => {return URLS.URL_WS + "/basket/set/suspect"}, 
        GET_SUSPECT_CONFIG: () => {return URLS.URL_WS + "/configuration/get/keys?configurationKeys={keys}"}, 
    },
    CATALOG: {
        // Categories
        GET_PARENT_CATEGORIES: () => { return URLS.URL_WS + "/catalog/category/get/parent/all"}, 
        GET_ALL_CATEGORIES:  () => {return URLS.URL_WS + "/catalog/category/get/all"},
        GET_CATEGORIES_BY_PARENT_ID: () => {return URLS.URL_WS + "/catalog/category/get/child/parent/id/{parentId}"},
        GET_CATEGORY_BY_ID: () => {return URLS.URL_WS + "/catalog/category/get/id/{categoryId}"}, 

        //Products
        GET_PRODUCTS_BY_CATEGORY: () => { return URLS.URL_WS + "/catalog/product/get/all/grouped/category/{categoryId}"}, 
        GET_PRODCUT_BY_ID: () => {return URLS.URL_WS + "/catalog/product/get/id/{productId}"}, 
        GET_PRODCUT_BY_EAN: () => {return URLS.URL_WS + "/catalog/product/get/ean/{ean}"}, 
        SEARCH_PRODUCT: () => {return URLS.URL_WS + "/search/?keyterm={keyterm}"}, 
        SPECIFICATIONS: () => {return URLS.URL_WS + "/catalog/product/get/specifications/id?productId={productId}&lang={lang}"},
        GET_CROSS_SELL: () => {return URLS.URL_WS + "/catalog/product/get/productscrosssell/id?productId={productId}"},
        GET_NUTRISCORE: () => {return "https://world.openfoodfacts.org/api/v0/product/{productEan}.json"}

    },
    VERSION: {
        GET_ALL_VERSIONS: () => {return URLS.URL_WS + "/version/getAll"}, 
    },
    CUSTOMER:{
        GET_CUSTOMER_INFOS: () => {return URLS.URL_WS + "/customer/get/token?token={token}"},
        LOGIN_CUSTOMER: () => {return URLS.URL_WS + "/customer/login"},
        REGISTER_CUSTOMER: () => {return URLS.URL_WS + "/customer/register"},
        REGISTER_CUSTOMER_ANONYM: () => {return URLS.URL_WS + "/customer/register/anonym"},
        UPDATE_CUSTOMER_PROFILE: () => {return URLS.URL_WS + "/customer/update"},
        UPDATE_PROFILE_PICTURE: () => {return URLS.URL_WS + "/customer/update/profile/picture"},
        CHECK_TOKEN: () => {return URLS.URL_WS + "/customer/check/token"},
        RESET_PASSWORD : () => {return URLS.URL_WS + "/customer/reset/password"},
        PROFILE_IMAGE_EXIST : () => {return URLS.URL_WS + "/image/profile/check?complexId={complexId}"},
        GET_PROFILE_IMAGE : () => {return URLS.URL_WS + "/customer/get/profile/picture/"},
    },
    LOYALTY:{
        GET_LOYALTY_INFOS: () => {return URLS.URL_WS + "/customer/get/loyalty/infos?jsonInfos={jsonInfos}"},
        UPDATE_LOYALTY_INFOS: () => {return URLS.URL_WS + "/customer/update/loyalty/informations"},
    },
    DEMONSTRATION:{
        GET_DEMONSTRATION: () => {return URLS.URL_WS_CUSTOMIZATION + "/demonstration/getDemo?demo={demoId}"},
        GET_DEMONSTRATION_LOGO: () => {return URLS.URL_WS_CUSTOMIZATION + "/demonstration/get/logo/{name}"},
    },
    FACE_RECOGNITION:{
        ADD_IMAGE: () => {return URLS.URL_FACE_RECOGNITION + "/corpus/add_image_by_url"}
    }

}

export const BASKET_URL = URLS.BASKET;
export const CATALOG_URL = URLS.CATALOG;
export const PWA_SHORT_URL= URLS.PWA_SHORT_URL;
export const CUSTOMER_URL = URLS.CUSTOMER;
export const LOYALTY_URL = URLS.LOYALTY;
export const DEMONSTRATION_URL = URLS.DEMONSTRATION;
export const VERSION_URL = URLS.VERSION;
export const FACE_RECOGNITION_URL = URLS.FACE_RECOGNITION;

export const  ALERT_SUCCESS = "success";
export const  ALERT_INFO = "info";
export const  ALERT_DANGER = "danger";
export const  ALERT_WARNING = "warning";

String.prototype.format = function(params) {
    let string = this;
    let keys = Object.keys(params)
    for (let i in keys) {
        string = string.replace("{" + keys[i] + "}", params[keys[i]])
    }
    return string
}