import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router);

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/need/scan',
            name: "NeedScan",
            component: () => import('@/components/NeedScan.vue'),
        },
        {
            path: "/",
            name: "Food",
            component: () => import('@/components/Food/Food.vue'),
            children: [
                {
                    path: '',
                    name: 'Index',
                    component: () => import('@/components/Food/Scan.vue')  
                },
                {
                    path: '/',
                    name: 'Index',
                    component: () => import('@/components/Food/Scan.vue')  
                },
                {
                    path: '/scan',
                    name: 'Scan',
                    component: () => import('@/components/Food/Scan.vue')  
                },
                {
                    path: '/basket',
                    name: "Basket",
                    component: () => import('@/components/Food/Basket.vue')  
                },
                {
                    path: '/paymentmethods',
                    name: "PaymentMethods",
                    component: () => import('@/components/Food/PaymentMethods.vue')  
                },
                {
                    path: '/paymentdone',
                    name: "PaymentDone",
                    props: (route) => ({
                        paymentSuccess: route.query.paymentSuccess
                    }),
                    component: () => import('@/components/Food/Ticket.vue') 
                },
                {
                    path: '/ticket',
                    name: "PaymentTicket",
                    component: () => import('@/components/Food/Ticket.vue') 
                },
                {
                    path: '/account',
                    component: () => import('@/components/Food/Account.vue') 
                },
                {
                    path: '/checkin',
                    name: "CheckIn",
                    component: () => import('@/components/Food/CheckIn.vue') 
                },
                {
                    path: '/schedule',
                    component: () => import('@/components/Food/StoreSchedule.vue') 
                },
                {
                    path: '/sips',
                    component: () => import('@/components/Food/PaymentMethods/Sips.vue') 
                },
                {
                    name: 'FoodIndex',
                    path: '/w3c',
                    component: () => import('@/components/Food/PaymentMethods/W3CPayment.vue') 
                },
                {
                    name: 'FoodIndex',
                    path: '/orders',
                    component: () => import('@/components/Food/MyOrders.vue') 
                }
            ]
        },
        {
            path: "/backoffice/:demoId/:locale/",
            name: "Backoffice",
            component: () => import('@/components/Backoffice/Backoffice.vue'),
            children: [
                {
                    name: "KeywordsList",
                    path: 'keywords',
                    component: () => import('@/components/Backoffice/KeywordsList.vue') 
                },
                {
                    name: "Basket",
                    path: 'basket/keyword/:keyword',
                    component: () => import('@/components/Backoffice/Basket.vue') 
                }
            ]
        }
    ]
})
